import { IncomingMessage } from 'http'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'

export interface Request extends IncomingMessage {
  cookies?: NextApiRequestCookies
}

function getReqLocalAuthCookie(req: Request): string | null {
  return (req.cookies && req.cookies['auth._token.local']) || null
}

function getReqFacebookAuthCookie(req: Request): string | null {
  return (req.cookies && req.cookies['auth._token.facebook']) || null
}

function getReqPartnerCookie(req: Request): string | null {
  return (req.cookies && req.cookies['auth.partner']) || null
}

function getReqIp(req: Request): string | undefined {
  // Uncomment below to override simulate request from different countries, locally.
  // return '0.0.0.0'
  const forwarded = req.headers['x-forwarded-for']
  return forwarded ? (!Array.isArray(forwarded) ? forwarded.split(/, /)[0] : forwarded[0]) : req.socket.remoteAddress
}

function getHeadersForDebugging(req: Request): Record<string, string | null> {
  return {
    'req.headers[x-forwarded-for]': req.headers['x-forwarded-for']?.toString() ?? null,
    'req.socket.remoteAddress': req.socket.remoteAddress ?? null,
  }
}

export function getClientIdentityForQuery(req: Request): {
  ip: string | undefined
  authLocalCookie: string | null
  authFacebookCookie: string | null
  headerDebugging: Record<string, string | null>
  partnerCookie: string | null
} {
  return {
    ip: getReqIp(req),
    authLocalCookie: getReqLocalAuthCookie(req),
    authFacebookCookie: getReqFacebookAuthCookie(req),
    headerDebugging: getHeadersForDebugging(req),
    partnerCookie: getReqPartnerCookie(req),
  }
}

export interface PartnerAttributionState {
  id: string
  code: string
  name: string
  slug: string
}

export function getPartnerAttributionStateFromCookies(cookies: NextApiRequestCookies): PartnerAttributionState | false {
  if (!cookies['auth.partner']) return false
  try {
    const parsed = JSON.parse(cookies['auth.partner'])
    return {
      id: parsed.id,
      code: parsed.code,
      name: parsed.name,
      slug: parsed.slug,
    }
  } catch (err) {
    console.error('Failed to parse partner attribution cookie with error', err)
    return false
  }
}
